@font-face {
  font-family: "BabelStoneHanPUA";
  src: url(./fonts/BabelStoneHanPUA.woff2) format("woff2");
}

@font-face {
  font-family: "UniHentaiKana";
  src: url(./fonts/UniHentaiKana-Regular.woff) format("woff");
}

@font-face {
  font-family: "HelveticaLatin";

  src: local("Helvetica Neue"),
    /* url(./fonts/HelveticaNeueMedium.eot), */
      url(./fonts/HelveticaNeueMedium.woff2) format("woff2"),
    url(./fonts/HelveticaNeueMedium.woff) format("woff"),
    url(./fonts/HelveticaNeueMedium.ttf) format("truetype"),
    local(--default-sans);

  font-weight: normal;
  font-style: normal;

  unicode-range: U+0, U+0080 - U+00FF, U+0100 - U+017F, U+8-9, U+d, U+1d,
    U+20-7e;
}

@font-face {
  font-family: "HelveticaLatin";

  src: local("Helvetica Neue"),
    /* url(./fonts/HelveticaNeueRegular.eot), */
      url(./fonts/HelveticaNeueMedium.woff2) format("woff2"),
    url(./fonts/HelveticaNeueMedium.woff) format("woff"),
    url(./fonts/HelveticaNeueMedium.ttf) format("truetype"),
    local(--default-sans);

  font-weight: bold;
  font-style: normal;

  unicode-range: U+0, U+0080 - U+00FF, U+0100 - U+017F, U+8-9, U+d, U+1d,
    U+20-7e;
}

html {
  /* --default-sans: HelveticaLatin, Hanamin, KaiXinSong; */
  --default-sans: HelveticaLatin, BabelStoneHanPUA, KaiXinSong, UniHentaiKana;
}

body {
  margin: 0;
  font-family: var(--default-sans)
    /* , -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif */;
  -webkit-text-size-adjust: 100%;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* to prevent pull to refresh on mobile chromium */
  overscroll-behavior-y: contain;
}

textarea {
  font-family: var(--default-sans);
}

.ui.button {
  font-family: var(--default-sans);
}

.ui.input > input {
  /* font-family: "HelveticaLatin", "Hanamin", "KaiXinSong"; */
  font-family: var(--default-sans);
  font-weight: bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
